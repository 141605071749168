<template>
  <div class="formbuilder-execute">
    <div v-loading="dataLoading" :element-loading-text="dataLoadingText">
      <div v-if="!isCurrentSlugPresent">Invalid step</div>
      <div v-else-if="allStepsCompleted" class="completed-status">
        <div class="mt-3">
          You have filled all required forms. To submit the form click on
          "Finish".
          <div class="completed-actions mt-2">
            <el-button size="mini" class="finish-drawer-stay" @click="saveFormbuilderTemplateData(false, false, true)"
              :loading="dataLoading">STAY & REVIEW</el-button>
            <el-button type="danger" size="mini" class="finish-drawer-finish"
              @click="saveFormbuilderTemplateData(false, false, false)" :loading="dataLoading">FINISH</el-button>
          </div>
        </div>
      </div>
      <div class v-else>
        <div v-if="getCurrentStep">
          <template>
            <!-- <el-scrollbar
              wrap-style="max-width: calc(100% - 0px);height: calc(100vh);"
            >-->
            <el-row style="text-align: center">
              <el-col :span="24">
                <img :src="getFormBuilder.logo" v-if="getFormBuilder && getFormBuilder.logo" width="150px" />
              </el-col>
              <el-col :span="24" v-if="getFormBuilder && getFormBuilder.name">
                <h1 class="form-title">
                  <b>{{ getFormBuilder.name }}</b>
                </h1>
              </el-col>
              <el-col :span="24">
                <h4 class="form-title" v-if="getCurrentTemplate &&
                  getCurrentTemplate.name &&
                  getCurrentStep.type == 'TEMPLATE'
                  " style="font-weight: 400">
                  {{
                    getTemplateTitle +
                    " " +
                    (checkIsRepeatable
                      ? currentRepeatableIndex > -1
                        ? currentRepeatableIndex + 1
                        : addingNewData
                          ? repeatableData.length + 1
                          : repeatableData.length + 1
                      : "")
                  }}
                </h4>
                <h4 class="form-title" v-if="getCurrentEntity &&
                    getCurrentEntity.name &&
                    getCurrentStep.type == 'ENTITY'
                    " style="font-weight: 400">
                  {{
                    getCurrentStep.settings &&
                    getCurrentStep.settings.receiver_title
                    ? getCurrentStep.settings.receiver_title
                    : getCurrentEntity.name
                  }}
                </h4>
              </el-col>
              <el-col :xl="{ span: 4, offset: 18 }" :lg="{ span: 4, offset: 18 }" :md="{ span: 4, offset: 18 }"
                :sm="{ span: 24 }" :xs="{ span: 24 }" v-if="checkisViewFormbuilder &&
                    getGuestUserFormbuildersList &&
                    getGuestUserFormbuildersList.length > 0 &&
                    this.$route.name !== 'ApplicationFormbuilderExecuteStep'
                    ">
                <el-button type="text" @click="gotoAllSubmissions">Go to all my submissions</el-button>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <InteractiveEntityExecute v-if="getCurrentStep.type == 'ENTITY' && reload" :step="getCurrentStep"
                  :templateData="getCurrentStep.template" :entityDataId="getCurrentEntityExistedData(getCurrentStep)"
                  :workflowDetailsId="formbuilderDataId" :is-execute="checkExecuteStatus" :isFormFormbuilder="true"
                  :form="form" :buttonText="getActionName" :currentStepIndex="getCurrentStepIndex"
                  @allFieldsCompleted="hideFinishButton = true"
                  @submitForm="saveFormbuilderTemplateData(false, false, false)" @submitEntityData="saveEntityDataStep"
                  @gotoPreStep="goToPrev" @prevStep="goToPrev"></InteractiveEntityExecute>
                <PreviewFormbuilderTemplate v-else :templateData="getCurrentStep.template" :entityDataId="entityDataId"
                  :workflowDetailsId="formbuilderDataId" :is-execute="checkExecuteStatus" :isFormFormbuilder="true"
                  :form="form" :buttonText="getActionName" :isRepeatable="checkIsRepeatable"
                  :minRecords="getMinNoOfRecords" :maxRecords="getMaxNoOfRecords"
                  @allFieldsCompleted="hideFinishButton = true" :isEntityExecute="true"
                  @submitForm="saveFormbuilderTemplateData" type="FORM_BUILDER_TEMPLATE" :currentStep="getCurrentStep" />
              </el-col>
            </el-row>
            <!-- </el-scrollbar> -->
          </template>
          <div v-if="checkIsRepeatable" class="repeatable-status">
            <div style="text-align: center" class="mt-1">
              <el-button @click="preRepeatableData" size="mini"
                v-if="this.repeatableData && this.repeatableData.length">Prev</el-button>
              <el-button size="mini" @click="nextRepeatableData"
                v-if="this.repeatableData && this.repeatableData.length">Next</el-button>
              <el-button v-if="(this.repeatableData.length + 1 < getMaxNoOfRecords ||
                this.repeatableData.length == 0) &&
                currentRepeatableIndex == -1 &&
                checkExecuteStatus
                " size="mini" type="primary" :disabled="checkDisabled || addNewRepeatableDisable"
                @click="saveFormbuilderTemplateData(false, true, false)" :loading="dataLoading">
                Add
                {{ getTemplateTitle }}
                {{ repeatableData.length + 1 }}
              </el-button>
            </div>
          </div>
          <div class="document-status" v-if="getCurrentStep.type != 'ENTITY'">
            <div style="text-align: center" class="mt-1" v-if="checkisInteractiveTemplate">
              <el-button v-if="getIsPrevVisible && checkExecuteStatus"
                @click="saveFormbuilderTemplateData(true, false, false)" :loading="dataLoading"
                :disabled="checkIsRepeatable && checkDisabled">Previous</el-button>
              <el-button v-if="getIsPrevVisible && !checkExecuteStatus" @click="goToPrev"
                :loading="dataLoading">Previous</el-button>
              <el-button type="primary" v-if="checkExecuteStatus" :disabled="checkDisabled || repeatableDataDisabled"
                @click="checkAllStepsCompleted" :loading="dataLoading">{{ getActionName }}</el-button>
              <el-button v-if="getViewName && !checkExecuteStatus" type="primary" @click="goToNext"
                :loading="dataLoading">Next</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-drawer :title="getFormBuilder && getFormBuilder.name ? getFormBuilder.name : ''" :with-header="false"
      :visible.sync="description_shown" direction="ttb" class="drawer-modal" :before-close="checkContinueClicked">
      <div class="esigns_logo">
        <img v-if="getSingleCompanyLogo" :src="getSingleCompanyLogo" alt="Nimble logo"
          style="max-height: 30px; margin-top: 15px; margin-left: 25px" />
        <img v-else style="width: 130px; margin-top: 15px; margin-left: 25px" src="@/assets/img/logo-white.svg" />
      </div>
      <div class="drawer-body">
        <p style="color: #303133; font-weight: 400; font-size: 18px" class="fw-500" v-if="getFormBuilder">
          {{ getFormBuilder.name }}
        </p>
        <el-scrollbar wrap-style="max-height: 100px;">
          <p style="
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 20px;
              color: #303133;
            ">
            <span v-if="getFormBuilder && getFormBuilder.description" v-html="getFormBuilder.description"
              class="description"></span>
            <span v-else>Hello, Please click on Continue</span>
          </p>
        </el-scrollbar>
        <el-divider class="mt-0"></el-divider>
        <el-button type="danger" size="mini" style="border-radius: 2px" @click="continueFilling">Continue</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import MobileRelatedHelper from "@/mixins/MobileRelatedHelper";
import FormbuilderHelper from "@/mixins/FormbuilderHelper.js";
import { mapGetters } from "vuex";
import { bus } from "../../main";
import userPermissionsHelper from "@/mixins/userPermissionsHelper";

export default {
  mixins: [MobileRelatedHelper, FormbuilderHelper, userPermissionsHelper],
  name: "Formbuilder-FormbuilderExecuteStep",
  components: {
    PreviewFormbuilderTemplate: () =>
      import("./../templates/previewFormbuilderTemplate.vue"),
    InteractiveEntityExecute: () =>
      import("../entity/interactiveEntityExecute.vue"),
    // RepeatableTemplatesData,
  },
  data() {
    return {
      entityId: null,
      currentSlug: null,
      loading: false,
      dataLoading: false,
      dataLoadingText: "Loading",
      formbuilderDataId: null,
      showRepeatingTemplatesData: false,
      refreshTemplate: false,
      isEdit: false,
      entityDataId: null,
      form: {},
      existedTemplateDataId: null,
      showFinishButton: false,
      description_shown: false,
      continue_clicked: false,
      hideFinishButton: false,
      reload: true,
      repeatableData: [],
      currentRepeatableIndex: -1,
      addingNewData: false,
      allStepsCompleted: false,
      stepContacts: [],
    };
  },
  beforeDestroy() {
    bus.$off("prev-step", (data) => {
      console.log("data destory", data);
    });
  },
  async mounted() {
    bus.$on("prev-step", () => {
      this.goToPrev();
    });

    await this.getNecessaryInfo();
    console.log(
      "this.getCurrentTemplateExistedData",
      this.getCurrentTemplateExistedData
    );
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize;
    bus.$on("pay-button-action-form-builder-template", (data) => {
      console.log(data)
      if (data) {
        this.saveFormbuilderData(false, false, false, true, data);
      }
    });
  },
  computed: {
    ...mapGetters("entities", ["getEntityDataById"]),
    ...mapGetters("entityRelationships", [
      "getEntityRelationships",
      "getCurrentEntityRelationships",
    ]),
    ...mapGetters("formBuilders", [
      "getFormBuilderAddStatus",
      "getFormBuilder",
      "getFormBuilderFillingInfo",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("auth", ["getAuthenticatedUser", "getDefaultDateFormat"]),
    ...mapGetters("formbuilderData", [
      "getFormbuilderDataCreateByTemplateStatus",
      "getNewFormbuilderData",
      "getNewFormbuilderDataError",
      "getUserFormbuilderData",
      "getGuestUserFormbuilderSubmissions",
      "getGuestUserFormbuildersList",
    ]),
    ...mapGetters("templatesData", [
      "getTemplatesData",
      "getTemplatesDataUpdateStatus",
      "getFileUploadURL",
      "getFileUploadRefId",
      "getDocumentUploadStatus",
      "getDocumentUploadStatusUpdated",
      "getUploadedFileData",
      "getEntityDataCreateByTemplateStatus",
      "getNewEntityData",
      "getEntityDataCreateByTemplateStatus",
      "getNewEntityCreateError",
      "getRepeatableTemplateIds",
      "getFormbuilderTemplatesDataUpdateStatus",
      "getFormbuilderTemplatesDataUpdateData",
    ]),
    ...mapGetters("contacts", [
      "getCreateContactForFormbuilderData",
      "getCreateContactForFormbuilderErrors",
      "getAllContacts",
    ]),
    ...mapGetters("company", ["getSingleCompanyLogo"]),

    getTemplateTitle() {
      if (
        this.getCurrentStep.standard_template &&
        this.getCurrentStep.settings.contact_entity_name
      ) {
        return (
          this.getCurrentStep.settings.contact_entity_name +
          (this.getCurrentStep.settings.contact_entity_type == "BUSINESS"
            ? " contact "
            : "")
        );
      }
      if (this.getCurrentStep?.settings?.receiver_title) {
        return this.getCurrentStep.settings.receiver_title;
      }
      return this.getCurrentTemplate && this.getCurrentTemplate.name
        ? this.getCurrentTemplate.name
        : " Data";
    },
    getCurrentEntityExistedData() {
      return (step) => {
        if (
          step?.settings?.isLoginEntity &&
          this.getAuthenticatedUser?.account_data_id &&
          this.getFormBuilder?.form_builders_owner == "ENTITY"
        ) {
          return this.getAuthenticatedUser.account_data_id;
        }
        const formbuilderTemplateData = this.getFormBuilder?.steps.find((e) => {
          let id = e.entity && e.entity._id ? e.entity._id : e.entity;
          if (id == step.entity._id || id == step.entity) {
            return e;
          }
        });
        const currenctTemplateData = (
          this.getUserFormbuilderData?.formbuilder_steps_data || []
        ).find((e) => {
          let id =
            e.entity_id && e.entity_id._id ? e.entity_id._id : e.entity_id;
          if (id == step.entity._id || id == step.entity) {
            return e;
          }
        });
        if (this.formbuilderDataId && currenctTemplateData) {
          return (
            currenctTemplateData?.entity_data_id ||
            currenctTemplateData?.entityDataId
          );
        }
        return (
          formbuilderTemplateData?.entityDataId ||
          formbuilderTemplateData?.entity_data_id ||
          step.entityDataId
        );
      };
    },
    checkExecuteStatus() {
      return this.$route.name != "GuestFormbuilderExecuteViewStep";
    },
    slugs() {
      if (
        this.getFormBuilder &&
        this.getFormBuilder.steps &&
        this.getFormBuilder.steps.length
      ) {
        return this.getFormBuilder.steps.map((step) => step.slug);
      }
      return [];
    },
    isCurrentSlugPresent() {
      if (this.slugs && this.slugs.length) {
        if (this.slugs.indexOf(this.currentSlug) === -1) {
          return false;
        }
        return true;
      }
      return true;
    },
    getStepsObject() {
      if (
        this.getFormBuilder &&
        this.getFormBuilder.steps &&
        this.getFormBuilder.steps.length
      ) {
        const data = {};
        this.getFormBuilder.steps.forEach((step, index, arr) => {
          // it will use to determine finish step
          step.has_next = index < arr.length - 1;
          // step.templateDataId = this.getTemplateDataIdMap[step.template_id]?this.getTemplateDataIdMap[step.template_id]:null;
          data[step.slug] = step;
        });
        return data;
      }
      return {};
    },
    getCurrentStep() {
      if (this.getStepsObject && this.currentSlug) {
        const step = this.getStepsObject[this.currentSlug];
        return step;
      }

      return null;
    },
    getCurrentStepIndex() {
      return this.getAllSteps.findIndex(
        (e) => e.slug == this.$route.params.template_code
      );
    },
    getCurrentTemplate() {
      if (this.getAllSteps && this.$route.params.template_code) {
        let step = this.getAllSteps.find(
          (e) => e && e.slug == this.$route.params.template_code
        );
        if (step && step.template) {
          return step.template;
        }
        return {};
      }
      return {};
    },
    getCurrentEntity() {
      if (this.getAllSteps && this.$route.params.template_code) {
        let step = this.getAllSteps.find(
          (e) => e && e.slug == this.$route.params.template_code
        );
        if (step && step.entity) {
          return step.entity;
        }
        return {};
      }
      return {};
    },
    getActionName() {
      if (
        this.getAllSteps &&
        this.$route.params.template_code &&
        this.getAllSteps.length > 1
      ) {
        let stepIndex = this.getAllSteps.findIndex(
          (e) => e && e.slug == this.$route.params.template_code
        );
        if (stepIndex == this.getAllSteps.length - 1) {
          return "Finish";
        }
        return "Next";
      }
      return "Finish";
    },
    getIsPrevVisible() {
      if (
        this.getAllSteps &&
        this.$route.params.template_code &&
        this.getAllSteps.length > 1
      ) {
        let stepIndex = this.getAllSteps.findIndex(
          (e) => e && e.slug == this.$route.params.template_code
        );
        if (stepIndex > 0) {
          return true;
        }
        return false;
      }
      return false;
    },
    getViewName() {
      if (
        this.getAllSteps &&
        this.$route.params.template_code &&
        this.getAllSteps.length > 1
      ) {
        let stepIndex = this.getAllSteps.findIndex(
          (e) => e && e.slug == this.$route.params.template_code
        );
        if (stepIndex == this.getAllSteps.length - 1) {
          return false;
        }
        return true;
      }
      return false;
    },
    getAllSteps() {
      if (this.getFormBuilder && this.getFormBuilder.steps) {
        return this.getFormBuilder.steps;
      }
      return [];
    },
    repeatableDataDisabled() {
      if (
        this.repeatableData &&
        this.repeatableData.length == 0 &&
        this.getMinNoOfRecords == 0
      ) {
        return false;
      } else if (this.checkIsRepeatable && this.repeatableData) {
        if (this.repeatableData.length + 1 < this.getMinNoOfRecords) {
          return true;
        }
      }
      return false;
    },
    addNewRepeatableDisable() {
      if (
        this.checkIsRepeatable &&
        (this.repeatableData.length || 0) + 1 >= this.getMaxNoOfRecords
      ) {
        return true;
      }
      return false;
    },
    checkDisabled() {
      if (this.getCurrentStep && this.getCurrentStep.template) {
        let requiredFields = this.getCurrentTemplateFields.filter((e) => {
          if (this.fieldFilledByCurrentUser(e.filled_by)) {
            if (e.inputType == "WEEKDAYS") {
              return (
                this.checkIsRequired(e) && this.checkIsNotFilled(e, this.form)
              );
            } else if (e.validations && e.validations.required) {
              if (e.inputType == "NUMBER") {
                return typeof this.form[e.key] != "number" || isNaN(this.form[e.key]);
              }
              else if (!this.form[e.key]) {
                return true;
              }

              else if (e.inputType == "DATE" || e.inputType == "DATE_TIME") {
                if (typeof this.form[e.key] != "object") {
                  return true;
                }
              }
              else {
                return (
                  this.form[e.key] === "" ||
                  this.form[e.key] === null ||
                  this.form[e.key] === undefined
                );
              }
            }
          }
        });
        if (requiredFields && requiredFields.length) {
          return true;
        }
      }
      return false;
    },
    getCurrentTemplateFields() {
      return this.getTemplateFields(this.getCurrentTemplate);
    },
    getRquiredFieldsCount() {
      return this.getCurrentTemplateFields.filter(
        (e) => e.validations && e.validations.required && (this.form[e.key] || typeof this.form[e.key] == 'number')
      ).length;
    },
    getTotalFieldsCount() {
      return this.getCurrentTemplateFields.filter(
        (e) => e.validations && e.validations.required && !e.value
      ).length;
    },
    getCurrentTemplateExistedData() {
      if (
        this.getUserFormbuilderData &&
        this.getUserFormbuilderData.formbuilder_steps_data
      ) {
        const currenctTemplateData =
          this.getUserFormbuilderData.formbuilder_steps_data.find(
            (e) => e.template_id == this.getCurrentTemplate._id
          );
        if (currenctTemplateData && currenctTemplateData.template_data_id) {
          return currenctTemplateData.template_data_id;
        }
        return {};
      }
      return {};
    },
    checkisViewFormbuilder() {
      return this.getFormBuilder && this.getFormBuilder.settings.view_responses;
    },
    checkisInteractiveTemplate() {
      if (
        this.getCurrentStep &&
        this.getCurrentStep.template &&
        this.getCurrentStep.template.use_interactive_questionare
      ) {
        if (
          this.$route.name == "GuestFormbuilderExecuteUpdateStep" ||
          this.$route.name == "GuestFormbuilderExecuteViewStep"
        ) {
          if (this.hideFinishButton) return false;
          else return true;
        }
        return false;
      } else if (this.getCurrentStep && this.getCurrentStep.type == "ENTITY") {
        return false;
      }
      return true;
    },
    checkIsRepeatable() {
      if (this.getCurrentStep?.settings?.is_repeatable) {
        return true;
      }
      return false;
    },
    getMinNoOfRecords() {
      if (
        this.getCurrentStep?.settings?.linked_min_field &&
        this.getFormBuilderFillingInfo
      ) {
        let [stepId, temp_id, key] =
          this.getCurrentStep.settings.linked_min_field.split("#");
        if (
          this.getFormBuilderFillingInfo[stepId] &&
          this.getFormBuilderFillingInfo[stepId][temp_id] &&
          this.getFormBuilderFillingInfo[stepId][temp_id][key]
        ) {
          let min = parseInt(
            this.getFormBuilderFillingInfo[stepId][temp_id][key]
          );
          return min;
        }
        return 1;
      } else if (this.getCurrentStep?.settings?.min_records) {
        return parseInt(this.getCurrentStep.settings.min_records);
      }
      return 1;
    },
    getMaxNoOfRecords() {
      if (
        this.getCurrentStep?.settings?.linked_field &&
        this.getFormBuilderFillingInfo
      ) {
        let [stepId, temp_id, key] =
          this.getCurrentStep.settings.linked_field.split("#");
        if (
          this.getFormBuilderFillingInfo[stepId] &&
          this.getFormBuilderFillingInfo[stepId][temp_id] &&
          this.getFormBuilderFillingInfo[stepId][temp_id][key]
        ) {
          let max = parseInt(
            this.getFormBuilderFillingInfo[stepId][temp_id][key]
          );
          if (max < this.getMinNoOfRecords) {
            return this.getMinNoOfRecords;
          }
          return max;
        }
        return 1000;
      } else if (this.getCurrentStep?.settings?.max_records) {
        return parseInt(this.getCurrentStep.settings.max_records);
      }
      return 1000;
    },
    checkRepPrevDisable() {
      if (
        this.repeatableData &&
        this.repeatableData.length &&
        (this.currentRepeatableIndex > 0 || this.addingNewData)
      ) {
        return false;
      }
      return true;
    },
    checkRepNextDisable() {
      if (
        this.repeatableData &&
        this.repeatableData.length &&
        this.currentRepeatableIndex + 1 < this.repeatableData.length &&
        !this.addingNewData
      ) {
        return false;
      }
      return true;
    },
    isFormbuilderCompleted() {
      if (this.getMinNoOfRecords) {
        return (
          this.repeatableData &&
          this.repeatableData.length == this.getMinNoOfRecords
        );
      }
      return true;
    },
  },
  methods: {
    checkIsRequired(data) {
      if (data.allow_multiple) {
        if (data.min_value > 0) {
          return true;
        }
        return false;
      }
      return data?.validations?.required ? true : false;
    },
    checkIsNotFilled(field, data) {
      if (field.allow_multiple) {
        if (field.min_value > 0) {
          if (
            data &&
            data[field.key] &&
            typeof data[field.key] == "object" &&
            data[field.key].length >= field.min_value
          ) {
            return false;
          }
          return true;
        }
        return false;
      } else {
        if (data && data[field.key]) {
          return false;
        }
        return true;
      }
    },
    async checkBusinessContacts() {
      if (
        this.getFormBuilder &&
        this.getFormBuilder.steps &&
        !this.$route.params.formbuilder_data_id
      ) {
        if (
          this.getCurrentStep?.standard_template &&
          this.getCurrentStep?.settings?.contact_entity_type == "BUSINESS"
        ) {
          await this.fetchLinkedContacts(
            this.getCurrentStep?.settings?.contact_entity,
            this.getAuthenticatedUser.account_data_id
          );
        }
      }
    },
    checkAllStepsCompleted() {
      if (
        this.getCurrentStepIndex == this.getAllSteps.length - 1 &&
        !this.getCurrentStep.standard_template
      ) {
        this.allStepsCompleted = true;
      } else {
        this.saveFormbuilderTemplateData(false, false, false, true);
      }
    },
    async saveEntityDataStep(entityData) {
      let params = await this.prepareParamDataForEntity(entityData);
      this.setFormbuilderFormData(
        this.form,
        this.getCurrentStepIndex,
        this.getCurrentTemplate._id
      );
      await this.$store.dispatch(
        "formbuilderData/createFormbuilderDataByTemplateData",
        params
      );
      if (this.getFormbuilderDataCreateByTemplateStatus) {
        this.dataLoading = false;
        this.$message({
          message: "Form data saved successfully",
          type: "success",
        });
        if (
          this.getNewFormbuilderData &&
          this.getNewFormbuilderData.formbuilder_data_status === "COMPLETED" &&
          this.getCurrentStepIndex == this.getAllSteps.length - 1
        ) {
          if (
            this.getFormBuilder.settings &&
            this.getFormBuilder.settings.success_and_redirect_page &&
            this.getFormBuilder.settings.success_and_redirect_page
              .redirect_to &&
            this.getFormBuilder.settings.success_and_redirect_page
              .redirect_to_url
          ) {
            if (
              this.isValidUrl(
                this.getFormBuilder.settings.success_and_redirect_page
                  .redirect_to_url
              ) &&
              this.isValidHttpUrl(
                this.getValidUrl(
                  this.getFormBuilder.settings.success_and_redirect_page
                    .redirect_to_url
                )
              )
            ) {
              window.location.href = this.getValidUrl(
                this.getFormBuilder.settings.success_and_redirect_page
                  .redirect_to_url
              );
            } else {
              this.gotoSuccessPage();
            }
          } else {
            this.gotoSuccessPage();
          }
        } else {
          this.goToNext();
        }
      } else {
        this.dataLoading = false;
        let message = "";
        if (this.getNewFormbuilderDataError) {
          message =
            this.getNewFormbuilderDataError.critical_error ||
            this.getNewFormbuilderDataError.message;
        }
        this.$notify.error({
          title: "Error",
          message: message,
        });
      }
    },
    async prepareParamDataForEntity(entityData) {
      return {
        formbuilder_details_id: this.getFormBuilder._id,
        entity_id: this.getCurrentStep?.entity?._id,
        entity_data_id: entityData?.entityDataId
          ? entityData.entityDataId
          : this.getCurrentEntityExistedData(this.getCurrentStep),
        formbuilder_step_id: this.getCurrentStep._id,
        description_shown: this.description_shown,
        template_completion_status: true,
        steps_data: this.getStepsDataForExistedFormbuilderData(entityData),
        contact: this.$route?.query?.user_id || this.getAuthenticatedUser._id,
        schedule_time: this.$route.query?.schedule_time || new Date(),
        time_interval: new Date(),
        formbuilder_data_id:
          this.$route.params && this.$route.params.formbuilder_data_id
            ? this.$route.params.formbuilder_data_id
            : null,
        template_id: "",
        ...(this.$route.query?.schedule_id && {
          schedule_id: this.$route.query?.schedule_id,
        }),
      };
    },
    checkContinueClicked() {
      if (this.continue_clicked) {
        this.description_shown = false;
      }
    },
    async continueFilling() {
      this.continue_clicked = true;
      this.description_shown = false;
      await this.checkBusinessContacts();
    },
    gotoAllSubmissions() {
      this.$router.push({
        name: this.$route.name == 'ApplicationFormbuilderExecuteStep' ? "ApplicaionFormbuilderUserSubmittedDataList" : "GuestFormbuilderUserSubmittedDataList",
        params: {
          formbuilder_code: this.$route.params.formbuilder_code,
          formbuilder_details_id: this.$route.params.formbuilder_details_id,
          user_id: this.getAuthenticatedUser._id,
        },
      });
    },
    async saveFormbuilderTemplateData(
      goToPrev,
      checkRepeatable,
      reviewIt,
      isStandard = false
    ) {
      this.dataLoading = true;
      if (
        this.getCurrentStep.standard_template &&
        this.getCurrentStep?.settings?.contact_entity &&
        !this.allStepsCompleted
      ) {
        let re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (
          !this.form.email ||
          (this.form?.email && !re.test(this.form.email))
        ) {
          this.$notify.error({
            title: "Error",
            message: "Invalid email!",
          });
          this.dataLoading = false;
          return;
        }
        this.dataLoading = true;
        this.dataLoadingText = this.getCurrentStep.settings.contact_entity_name
          ? "Creating new " + this.getCurrentStep.settings.contact_entity_name
          : "Creating data";
        let existedContact =
          this.repeatableData[this.currentRepeatableIndex] &&
            this.repeatableData[this.currentRepeatableIndex].email
            ? this.repeatableData[this.currentRepeatableIndex]
            : null;
        if (existedContact?.email) {
          let existedContactDetails = (this.stepContacts || [])
            .filter((e) => e.email)
            .find(
              (e) => e.email.toLowerCase() == existedContact.email.toLowerCase()
            );
          if (existedContactDetails) {
            await this.saveFormbuilderData(goToPrev, checkRepeatable, reviewIt);
            return;
          }
        }
        this.dataLoading = true;
        await this.$store.dispatch("contacts/createContactForFormbuilder", {
          ...this.form,
          ...{
            email: this.form.email.toLowerCase(),
            name: this.form.first_name
              ? this.form.first_name +
              " " +
              (this.form.last_name ? this.form.last_name : "")
              : "",
            company_id: this.getFormBuilder.company,
            created_by: this.getFormBuilder.created_by,
            user_id: this.getFormBuilder.created_by,
            contact_type: this.getCurrentStep.settings.contact_entity,
            entity_id: this.getCurrentStep.settings.contact_entity,
          },
          ...(this.getCurrentStep?.settings?.contact_entity_type ==
            "BUSINESS" && {
            account_data_id: this.getAuthenticatedUser.account_data_id,
          }),
          ...(existedContact && {
            contact_id: existedContact._id,
          }),
        });
        if (this.getCreateContactForFormbuilderData?._id) {
          this.dataLoading = false;
          this.stepContacts.push(this.getCreateContactForFormbuilderData);
          // if(this.currentRepeatableIndex > -1 && this.repeatableData[this.currentRepeatableIndex]){
          //   this.repeatableData[this.currentRepeatableIndex] = {...this.getCreateContactForFormbuilderData};
          // }else{
          //   this.repeatableData.push(this.getCreateContactForFormbuilderData);
          // }
          if (
            isStandard &&
            this.getCurrentStepIndex == this.getAllSteps.length - 1
          ) {
            this.allStepsCompleted = true;
          } else {
            await this.saveFormbuilderData(goToPrev, checkRepeatable, reviewIt);
          }
        } else {
          this.dataLoading = false;
          let msg =
            "Error while creating " +
            this.getCurrentStep.settings.contact_entity_name;
          if (
            this.getCreateContactForFormbuilderErrors?.error_code ==
            "ALREADY_EXISTED"
          ) {
            msg = "This email is already registered as other contact by sender";
          }
          this.$notify.error({
            title: "Error",
            message: msg,
          });
        }
      } else {
        this.dataLoading = true;
        await this.saveFormbuilderData(goToPrev, checkRepeatable, reviewIt);
      }
    },
    async saveFormbuilderData(goToPrev, checkRepeatable, reviewIt, isFromPayButton = false, payButtonData = {}) {
      this.dataLoadingText = reviewIt ? "Gathering data" : "Saving form data";
      this.dataLoading = true;
      let params = await this.prepareParamData();
      this.setFormbuilderFormData(
        this.form,
        this.getCurrentStepIndex,
        this.getCurrentTemplate._id
      );


      console.log("Form builder data", params)
      if (this.getCurrentStep.standard_template) {
        /* Form builder Mixin method (Update contacts)*/
        this.updatedContactDetails(this.repeatableData, this.stepContacts);
      }
      await this.$store.dispatch(
        "formbuilderData/createFormbuilderDataByTemplateData",
        params
      );
      if (this.getFormbuilderDataCreateByTemplateStatus) {

        if (isFromPayButton && payButtonData && payButtonData.action) {
          bus.$emit("initTemplatePayButton", { ...payButtonData, formBuilderDataId: this.getNewFormbuilderData._id, formbuilder_step_id: params.formbuilder_step_id, formbuilder_details_id: params.formbuilder_details_id, template_id: params.template_id })
        }

        this.dataLoading = false;
        if (!reviewIt) {
          this.$message({
            message: "Form data saved successfully",
            type: "success",
          });
        }
        if (goToPrev) {
          this.goToPrev();
        } else if (reviewIt) {
          this.startAgain(0);
        } else {
          let nextTemplate = this.getAllSteps[this.getCurrentStepIndex];
          if (
            this.getNewFormbuilderData &&
            this.getNewFormbuilderData.formbuilder_data_status ===
            "COMPLETED" &&
            this.getCurrentStepIndex == this.getAllSteps.length - 1
          ) {
            if (
              this.checkIsRepeatable &&
              checkRepeatable &&
              this.repeatableData.length < this.getMaxNoOfRecords
            ) {
              // this.addingNewData = true;
              // this.form = {};
              this.$router.push({
                name: this.checkExecuteStatus
                  ? "GuestFormbuilderExecuteUpdateStep"
                  : "GuestFormbuilderExecuteViewStep",
                params: {
                  template_code: nextTemplate.slug,
                  formbuilder_code: this.getFormBuilder.code,
                  formbuilder_details_id: this.getFormBuilder._id,
                  formbuilder_data_id: this.getNewFormbuilderData._id,
                },
                query: {
                  ...this.$route.query,
                  ...{ repeatableIndex: this.repeatableData.length },
                  key: Math.floor(Math.random() * 100000)
                },
              });
            } else if (
              this.getFormBuilder.settings &&
              this.getFormBuilder.settings.success_and_redirect_page &&
              this.getFormBuilder.settings.success_and_redirect_page
                .redirect_to &&
              this.getFormBuilder.settings.success_and_redirect_page
                .redirect_to_url
            ) {
              if (
                this.isValidUrl(
                  this.getFormBuilder.settings.success_and_redirect_page
                    .redirect_to_url
                ) &&
                this.isValidHttpUrl(
                  this.getValidUrl(
                    this.getFormBuilder.settings.success_and_redirect_page
                      .redirect_to_url
                  )
                )
              ) {
                window.location.href = this.getValidUrl(
                  this.getFormBuilder.settings.success_and_redirect_page
                    .redirect_to_url
                );
              } else {
                this.gotoSuccessPage();
              }
            } else {
              this.gotoSuccessPage();
            }
          } else {
            if (
              this.checkIsRepeatable &&
              this.repeatableData.length < this.getMaxNoOfRecords &&
              checkRepeatable
            ) {
              // this.addingNewData = true;
              // this.form = {};
              this.$router.push({
                name: this.checkExecuteStatus
                  ? "GuestFormbuilderExecuteUpdateStep"
                  : "GuestFormbuilderExecuteViewStep",
                params: {
                  template_code: nextTemplate.slug,
                  formbuilder_code: this.getFormBuilder.code,
                  formbuilder_details_id: this.getFormBuilder._id,
                  formbuilder_data_id: this.getNewFormbuilderData._id,
                },
                query: {
                  ...this.$route.query,
                  ...{ repeatableIndex: this.repeatableData.length },
                  key: Math.floor(Math.random() * 100000)
                },
              });
            } else {
              this.goToNext();
            }
          }
        }
      } else {
        this.dataLoading = false;
        let message = "";
        if (this.getNewFormbuilderDataError) {
          message =
            this.getNewFormbuilderDataError.critical_error ||
            this.getNewFormbuilderDataError.message;
        }
        this.$notify.error({
          title: "Error",
          message: message,
        });
      }
    },
    getValidUrl(url) {
      if (!url.includes("http://") && !url.includes("https://"))
        return "https://" + url;
      return url;
    },
    isValidUrl(url) {
      let re =
        /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi;
      if (re.test(url)) {
        return true;
      } else {
        return false;
      }
    },
    isValidHttpUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    gotoSuccessPage() {
      let pageName =
        this.$route.name == "CompanyFormbuilderExecuteStep"
        ? "CompanyUserFormbuilderCompleted"
        : this.$route.name == "ApplicationFormbuilderExecuteStep"
        ? "ApplicaionFormbuilderCompleted"
        : "GuestFormbuilderCompleted";
      this.$router.push({
        name: pageName,
        params: {
          formbuilder_code: this.getFormBuilder.code,
          formbuilder_details_id: this.getFormBuilder._id,
          formbuilder_data_id: this.getNewFormbuilderData._id,
        },
      });
    },
    goToPrev() {
      if (this.getCurrentStepIndex < this.getAllSteps.length) {
        let params = this.$route.params;
        let index = this.getCurrentStepIndex;
        let prevTemplate = this.getAllSteps[index - 1];
        params.template_code = prevTemplate.slug;
        this.$router.push({
          name: this.checkExecuteStatus
            ? "GuestFormbuilderExecuteUpdateStep"
            : "GuestFormbuilderExecuteViewStep",
          params: {
            template_code: prevTemplate.slug,
            formbuilder_code: this.getFormBuilder.code,
            formbuilder_details_id: this.getFormBuilder._id,
            formbuilder_data_id: this.getNewFormbuilderData._id,
          },
          query: { ...this.$route.query, key: Math.floor(Math.random() * 100000) }
        });
      }
    },
    startAgain(index) {
      let params = this.$route.params;
      let nextTemplate = this.getAllSteps[index];
      params.template_code = nextTemplate.slug;
      this.reload = false;
      setTimeout(() => {
        this.reload = true;
      });
      this.$router.push({
        name: this.checkExecuteStatus
          ? "GuestFormbuilderExecuteUpdateStep"
          : "GuestFormbuilderExecuteViewStep",
        params: {
          template_code: nextTemplate.slug,
          formbuilder_code: this.getFormBuilder.code,
          formbuilder_details_id: this.getFormBuilder._id,
          formbuilder_data_id: this.getNewFormbuilderData._id,
        },
        query: { ...this.$route.query, key: Math.floor(Math.random() * 100000) }
      });
    },
    goToNext() {
      if (this.getCurrentStepIndex < this.getAllSteps.length - 1) {
        let params = this.$route.params;
        let index = this.getCurrentStepIndex;
        let nextTemplate = this.getAllSteps[index + 1];
        params.template_code = nextTemplate.slug;
        this.reload = false;
        setTimeout(() => {
          this.reload = true;
        });
        this.$router.push({
          name: this.checkExecuteStatus
            ? "GuestFormbuilderExecuteUpdateStep"
            : "GuestFormbuilderExecuteViewStep",
          params: {
            template_code: nextTemplate.slug,
            formbuilder_code: this.getFormBuilder.code,
            formbuilder_details_id: this.getFormBuilder._id,
            formbuilder_data_id: this.getNewFormbuilderData._id,
          },
          query: { ...this.$route.query, key: Math.floor(Math.random() * 100000) },
        });
      }
    },
    async prepareParamData() {
      let form = await this.prepareFormData();
      return {
        template_data: form,
        formbuilder_details_id: this.getFormBuilder._id,
        template_id: this.getCurrentTemplate._id,
        is_repeatable: this.checkIsRepeatable,
        min_records: this.getMinNoOfRecords,
        template_data_id:
          this.getCurrentTemplateExistedData &&
            this.getCurrentTemplateExistedData._id
            ? this.getCurrentTemplateExistedData._id
            : null,
        formbuilder_step_id: this.getCurrentStep._id,
        description_shown: this.description_shown,
        template_completion_status: this.checkIsRepeatable
          ? this.isFormbuilderCompleted
          : this.getRquiredFieldsCount === this.getTotalFieldsCount,
        steps_data: this.getStepsDataForExistedFormbuilderData(),
        contact: this.getAuthenticatedUser._id,
        schedule_time: this.$route.query?.schedule_time || new Date(),
        time_interval: new Date(),
        formbuilder_data_id:
          this.$route.params && this.$route.params.formbuilder_data_id
            ? this.$route.params.formbuilder_data_id
            : null,
        ...(this.$route.query?.schedule_id && {
          schedule_id: this.$route.query?.schedule_id,
        }),
        standard_template: this.getCurrentStep.standard_template || false,
        created_contacts: this.stepContacts.flatMap((e) => e._id),
      };
    },
    getStepsDataForExistedFormbuilderData(entityData) {
      if (
        this.getUserFormbuilderData &&
        this.getUserFormbuilderData.formbuilder_steps_data &&
        this.$route.params &&
        this.$route.params.formbuilder_data_id &&
        this.$route.params.formbuilder_data_id ==
        this.getUserFormbuilderData._id &&
        this.getUserFormbuilderData.formbuilder_steps_data.length
      ) {
        return this.getUserFormbuilderData.formbuilder_steps_data;
      }
      return this.getFormBuilder.steps.map((e) => {
        if (e.template) {
          e.template_id =
            e.template && e.template._id ? e.template._id : e.template;
        } else if (e.entity) {
          e.entity_id =
            e.entity && e.entity._id
              ? e.entity._id
              : e.entity_id
                ? e.entity_id
                : e.entity;
          if (entityData && entityData.entity_id == e.entity_id) {
            e.entity_data_id = entityData.entityDataId
              ? entityData.entityDataId
              : e?.entityDataId;
          }
        }
        return e;
      });
    },
    async prepareFormData() {
      // this.dataLoading = true;
      this.dataLoadingText = "Preparing template data...";
      for (let [key, value] of Object.entries(this.form)) {
        if (
          value &&
          typeof value == "object" &&
          value.type == "DOCUMENT" &&
          value.new == true &&
          value.files
        ) {
          this.dataLoadingText = "Uploading files...";
          let path = await this.uploadFile(value.files);
          this.form[key].path = path;
          this.form[key].new = false;
        }
      }
      // this.dataLoading = false;
      //Update parent entity data by entity variable - mixin method
      let updateParams = {
        company_id: this.getFormBuilder.company,
        user_id: this.getFormBuilder.created_by,
      };
      await this.updateParentDataByEntityVariable(
        this.getCurrentTemplateFields,
        updateParams
      );
      if (this.checkIsRepeatable) {
        await this.$store.dispatch(
          "templatesData/fetchRepeatableTemplateIds",
          this.getMaxNoOfRecords + 10
        );
        if (
          this.currentRepeatableIndex > -1 &&
          this.repeatableData[this.currentRepeatableIndex]
        ) {
          this.repeatableData[this.currentRepeatableIndex] = {
            ...{
              indexKey:
                this.getRepeatableTemplateIds[this.currentRepeatableIndex],
            },
            ...this.form,
          };
        } else {
          this.repeatableData.push({
            ...{
              indexKey:
                this.getRepeatableTemplateIds[this.repeatableData.length],
            },
            ...this.form,
          });
        }
        return {
          [this.getCurrentTemplate.name.toLowerCase().split(" ").join("_")]:
            this.repeatableData,
        };
      } else {
        return this.form;
      }
    },
    async uploadFile(file) {
      file.forEach(async (el) => {
        await this.getFileUploadUrl(el);
        await this.uploadSelectedDocument(el.raw);
        if (this.getUploadedFileData) {
          return (
            this.getUploadedFileData.slug + "/" + this.getUploadedFileData.key
          );
        } else {
          return "";
        }
      });
    },
    async getFileUploadUrl(file) {
      let data = {
        file_name: file.raw.name,
        file_type: file.raw.type,
        file_size_in_kb: file.raw.size / 1000,
      };
      await this.$store.dispatch("templatesData/fetchDocumentUploadUrl", data);
    },
    async uploadSelectedDocument(file) {
      this.loadingText = "Uploading Document...";
      // uploding to S3
      let params = {
        file: file,
        upload_url: this.getFileUploadURL,
      };
      await this.$store.dispatch(
        "templatesData/uploadDocumentToGeneratedUrl",
        params
      );

      if (this.getDocumentUploadStatus) {
        // UPDATEING STATUS
        let params = {
          file_ref_id: this.getFileUploadRefId,
        };
        await this.$store.dispatch(
          "templatesData/updateDocumentUploadStatus",
          params
        );
        if (this.getDocumentUploadStatusUpdated) {
          console.log("fileuploaded");
        } else {
          this.$message("Sorry ! Error while file uploading");
        }
      } else {
        this.$message("Sorry ! Error while file uploading");
      }
    },
    getTemplateFields(data) {
      let fields = [];
      (data.sections || []).forEach((e) => {
        fields = [...fields, ...e.fields];
      });
      fields = fields.map((e) => {
        e.template_id = data._id;
        return e;
      });
      return fields;
    },
    async getNecessaryInfo() {
      if (
        this.$route.name === "GuestFormbuilderExecuteStep" ||
        this.$route.name === "GuestFormbuilderExecuteUpdateStep"
      ) {
        this.isEdit = true;
      } else {
        this.isEdit = false;
      }
      this.currentSlug = this.$route.params.template_code;
      this.formbuilderDataId = this.$route.params.formbuilder_data_id || null;
      this.loading = true;
      this.step = this.$route.query.step || 1;
      this.entityId = this.$route.params.entity_id;
      this.loading = false;
      if (this.formbuilderDataId) {
        await this.fetchFormbuilderData();
      } else {
        this.description_shown = true;
        // setTimeout(() => {
        //   this.checkBusinessContacts();
        // }, 1000);
      }
    },
    async fetchLinkedContacts(entityId, entityDataId) {
      if (entityId && entityDataId) {
        this.dataLoading = true;
        this.dataLoadingText = "Getting existed contacts details";
        await this.$store.dispatch("contacts/fetchAllContactsByEntity", {
          get_all: true,
          contactType: entityId,
          accountName: entityDataId,type:'CONTACT'
        });
        if (this.getAllContacts?.data && this.getAllContacts.data.length) {
          this.repeatableData = [
            ...this.getAllContacts.data.filter(
              (e) =>
                e?.email.toLowerCase() !==
                this.getAuthenticatedUser?.email.toLowerCase()
            ),
          ];
          this.stepContacts = [...[], ...this.repeatableData];
          if (this.repeatableData.length) {
            this.currentRepeatableIndex = 0;
            let formData = {
              ...this.repeatableData[this.currentRepeatableIndex],
            };
            this.setFormValues(formData);
          }
          this.dataLoading = false;
        } else {
          this.dataLoading = false;
        }
      }
    },
    nextRepeatableData() {
      if (
        this.getCurrentStep.standard_template &&
        this.repeatableData[this.currentRepeatableIndex]
      ) {
        this.repeatableData[this.currentRepeatableIndex] = { ...this.form };
      }
      if (
        this.currentRepeatableIndex + 1 >= this.repeatableData.length &&
        this.repeatableData.length < this.getMaxNoOfRecords &&
        this.checkExecuteStatus
      ) {
        this.form = {};
        this.addingNewData = true;
        this.currentRepeatableIndex = -1;
      } else if (
        this.checkExecuteStatus &&
        this.currentRepeatableIndex != -1 &&
        this.currentRepeatableIndex + 1 < this.repeatableData.length &&
        this.repeatableData[this.currentRepeatableIndex + 1]
      ) {
        this.addingNewData = false;
        this.currentRepeatableIndex = this.currentRepeatableIndex + 1;
        let formData = {
          ...this.repeatableData[this.currentRepeatableIndex],
        };
        this.setFormValues(formData);
      } else if (!this.checkExecuteStatus) {
        let formData;
        if (
          this.currentRepeatableIndex > -1 &&
          this.currentRepeatableIndex + 1 < this.repeatableData.length
        ) {
          this.currentRepeatableIndex = this.currentRepeatableIndex + 1;
          formData = {
            ...this.repeatableData[this.currentRepeatableIndex],
          };
        } else {
          this.currentRepeatableIndex = 0;
          formData = {
            ...this.repeatableData[this.currentRepeatableIndex],
          };
        }
        this.setFormValues(formData);
      } else {
        this.$message({
          message: "Last data of the list. Can't move forward ",
          type: "warning",
        });
      }
    },
    preRepeatableData() {
      if (
        this.getCurrentStep.standard_template &&
        this.repeatableData[this.currentRepeatableIndex]
      ) {
        this.repeatableData[this.currentRepeatableIndex] = { ...this.form };
      }
      if (
        (this.currentRepeatableIndex > 0 || this.addingNewData) &&
        this.checkExecuteStatus
      ) {
        this.currentRepeatableIndex =
          this.currentRepeatableIndex > -1
            ? this.currentRepeatableIndex - 1
            : this.repeatableData.length - 1;
        this.addingNewData = false;
        this.form = {};
        let formData = { ...this.repeatableData[this.currentRepeatableIndex] };
        this.setFormValues(formData);
      } else if (!this.checkExecuteStatus) {
        let formData;
        if (this.currentRepeatableIndex > 0) {
          this.currentRepeatableIndex = this.currentRepeatableIndex - 1;
          formData = {
            ...this.repeatableData[this.currentRepeatableIndex],
          };
        } else {
          this.currentRepeatableIndex = this.repeatableData.length - 1;
          formData = {
            ...this.repeatableData[this.currentRepeatableIndex],
          };
        }
        this.setFormValues(formData);
      } else {
        this.$message({
          message: "Starting of the list. Can't go previous ",
          type: "warning",
        });
      }
    },
    setTemplateDataToForm() {
      if (
        this.getCurrentTemplateExistedData &&
        this.getCurrentTemplateExistedData.template_data
      ) {
        let formData = {};
        if (this.checkIsRepeatable) {
          let form =
            this.getCurrentTemplateExistedData.template_data[
            Object.keys(this.getCurrentTemplateExistedData.template_data)[0]
            ];
          this.repeatableData = [...this.repeatableData, ...form];
          this.currentRepeatableIndex =
            this.repeatableData.length >= this.getMaxNoOfRecords
              ? this.repeatableData.length - 1
              : -1;
          if (
            this.currentRepeatableIndex > -1 &&
            form[this.currentRepeatableIndex]
          ) {
            formData = { ...form[this.currentRepeatableIndex] };
          } else if (this.checkExecuteStatus) {
            this.addingNewData = true;
            formData = {};
          } else {
            this.currentRepeatableIndex = 0;
            formData = { ...form[this.currentRepeatableIndex] };
          }
        } else {
          formData = this.getCurrentTemplateExistedData.template_data;
        }
        this.setFormValues(formData);
      }
    },
    setFormValues(formData) {
      this.getCurrentTemplateFields.forEach((e) => {
        this.$set(this.form, e.key, formData[e.key]);
        if (e.input_type == "QUESTION" && e.has_other_option) {
          this.$set(
            this.form,
            `${e.key}/otheroption`,
            formData[e.key + "/otheroption"]
              ? formData[e.key + "/otheroption"]
              : ""
          );
        }
        if (e.input_type === "PHONE_COUNTRY_CODE") {
          this.$set(
            this.form,
            e.key + "_code",
            formData && formData[e.key + "_code"]
              ? formData[e.key + "_code"]
              : {}
          );
        }
        if (e.input_type === "CURRENCY") {
          this.$set(this.form, e.key + "_currency", e.validations.currency);
        }
        if (formData && formData[e.key + "/name"]) {
          this.$set(this.form, e.key + "/name", formData[e.key + "/name"]);
        }
      });
      if (formData["indexKey"]) {
        this.$set(this.form, "indexKey", formData["indexKey"]);
      }
    },
    async fetchFormbuilderData() {
      this.dataLoadingText = "Fetching existed form data";
      this.dataLoading = true;
      await this.$store.dispatch("formbuilderData/fetchUserFormbuilderData", {
        id: this.formbuilderDataId,
        include_template_data: true,
        include_contacts_data: true,
      });
      if (this.getUserFormbuilderData) {
        // this.setEntityDataSteps();
        let formbuilderData = { ...this.getFormBuilder };
        let steps = this.getUserFormbuilderData.formbuilder_steps_data;
        formbuilderData.steps = (
          formbuilderData && formbuilderData.steps ? formbuilderData.steps : []
        ).map((el) => {
          if (el.type == "ENTITY") {
            let currentStep = steps.find(
              (e) => e.entity_id == el?.entity?._id || e.entity_id == el.entity
            );
            if (currentStep?.entity_data_id) {
              el.entityDataId = currentStep.entity_data_id;
            }
          }
          return el;
        });
        this.$store.commit(
          "formBuilders/setFormBuilder",
          {},
          {
            root: true,
          }
        );
        this.$store.commit("formBuilders/setFormBuilder", formbuilderData, {
          root: true,
        });
        console.log("Delll xps", this.getCurrentStep);
        if (this.getCurrentStep?.type == "TEMPLATE") {
          if (this.getCurrentStep?.standard_template) {
            let formData = {};
            const currenctTemplateData = (
              this.getUserFormbuilderData.formbuilder_steps_data || []
            ).find((e) => e.template_id == this.getCurrentTemplate._id);
            if (currenctTemplateData?.created_contacts) {
              this.repeatableData = [...currenctTemplateData.created_contacts];
              this.stepContacts = [...currenctTemplateData.created_contacts];
              this.currentRepeatableIndex =
                this.repeatableData.length >= this.getMaxNoOfRecords
                  ? this.repeatableData.length - 1
                  : -1;
              if (
                this.currentRepeatableIndex > -1 &&
                this.repeatableData[this.currentRepeatableIndex]
              ) {
                formData = {
                  ...this.repeatableData[this.currentRepeatableIndex],
                };
              } else if (this.checkExecuteStatus) {
                this.addingNewData = true;
                formData = {};
              } else {
                this.currentRepeatableIndex = 0;
                formData = {
                  ...this.repeatableData[this.currentRepeatableIndex],
                };
              }
            }
            this.setFormValues(formData);
          } else {
            await this.setTemplateDataToForm();
          }
        }
        this.description_shown = this.getUserFormbuilderData.description_shown;
        this.dataLoading = false;
        this.dataLoadingText = "Loading";
      } else {
        this.dataLoading = false;
        this.$notify.error({
          title: "Error",
          message: "Error while fetching form builder data",
        });
      }
    },
    setEntityDataSteps() {
      let formbuilderData = { ...this.getFormBuilder };
      formbuilderData.steps = formbuilderData.steps.map((e) => {
        if (
          e?.type == "ENTITY" &&
          !e?.settings?.isLoginEntity &&
          this.getFormBuilder?.form_builders_owner == "ENTITY"
        ) {
          e.entityDataId = this.getCurrentEntityExistedData(e);
        }
        return e;
      });
      this.$store.commit(
        "formBuilders/setFormBuilder",
        {},
        {
          root: true,
        }
      );
      this.$store.commit("formBuilders/setFormBuilder", formbuilderData, {
        root: true,
      });
    },
  },
  watch: {
    "$route.params.slug"(slug) {
      this.currentSlug = slug;
    },
  },
};
</script>

<style lang="scss" scoped>
.completed-status {
  margin-top: 10%;
  text-align: center !important;
  font-size: 16px;
  color: black;
  border-top: #ccc 1px solid;
  border-bottom: #ccc 1px solid;
  padding: 25px;

  .finish-drawer-stay {
    color: #4c4b4e;
    font-weight: 400;
    // height: 36px;
    text-align: center;
    min-width: 105px;
  }

  .finish-drawer-finish {
    border-radius: 2px;
    // height: 36px;
    text-align: center;
  }
}

.formbuilder-title {
  position: fixed;
  z-index: 1000;
}

.formbuilder-execute {
  position: absolute;
  top: 75px;
  // overflow: hidden;
}

.repeatable-status {
  position: fixed;
  bottom: 70px;
  background: white;
  border: #ccc 1px solid;
  // border-radius: 10px;
  height: 50px;
  width: 100%;

  // text-align: center !important;;
  @media (min-width: 1300px) {
    &.builder-default {
      width: 1220px;
    }
  }

  @media (min-width: 320px) {
    max-width: 1220px;
  }
}

.document-status {
  position: fixed;
  bottom: 0px;
  background: white;
  border: #ccc 1px solid;
  border-radius: 10px;
  height: 70px;
  width: 100%;

  @media (min-width: 1300px) {
    &.builder-default {
      width: 1200px;
    }
  }

  @media (min-width: 320px) {
    max-width: 1200px;
  }
}

.form-title {
  color: #303133;
}

.esigns_logo {
  background: #1b487e;
  height: 65px;
}

.drawer-body {
  padding: 25px;
}

.drawer-modal {
  height: 1000px !important;

  @media (max-width: 991.98px) {
    height: 1500px !important;
  }
}
</style>
